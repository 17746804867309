import type { FilterModel, Row } from 'src/components/DataGrid';
import { type DenormalizedBrandSales } from 'src/records/types/DenormalizedBrandSales';
import type { Entity, Volume } from 'src/types/Entity';
import { type ModelledForecastEntity } from 'src/types/entity/ModelledForecast';

export const calculatePreviousForecastTotals = (
  data: DenormalizedBrandSales[],
  filters?: FilterModel
): Volume | undefined => {
  if (!filters?.[0]) return;

  const { field, value: categoryValue } = filters[0];

  const dataFilteredByCategory = data.filter((rowData: Row) => {
    if (Array.isArray(categoryValue)) {
      return categoryValue.includes(rowData[String(field)]);
    }
    const fieldValue = rowData[String(field)];
    if (typeof categoryValue === 'string' && typeof fieldValue === 'string') {
      return fieldValue.toLowerCase().includes(categoryValue.toLowerCase());
    }
    return categoryValue === rowData[String(field)];
  });

  const totalVolumes = dataFilteredByCategory.reduce<Volume>((acc, sale) => {
    const updatedAcc = { ...acc };
    Object.keys(sale.volume).forEach(key => {
      const year = parseInt(key);
      const volumeValue = sale.volume[`${year}`];

      if (volumeValue != null && !isNaN(volumeValue)) {
        if (updatedAcc[`${year}`] != null) {
          updatedAcc[`${year}`] += volumeValue;
        } else {
          updatedAcc[`${year}`] = volumeValue;
        }
      }
    });
    return updatedAcc;
  }, {});

  return totalVolumes;
};

export const calculateModelledForecastTotals = (
  data: DenormalizedBrandSales[],
  getEntity: (entityType: string, entityGUID: string) => Entity | undefined,
  filters?: FilterModel
): Volume | undefined => {
  if (!filters?.[0]) return;

  const { field, value: categoryValue } = filters[0];

  const dataFilteredByCategory = data.filter((rowData: Row) => {
    if (Array.isArray(categoryValue)) {
      return categoryValue.includes(rowData[String(field)]);
    }
    const fieldValue = rowData[String(field)];
    if (typeof categoryValue === 'string' && typeof fieldValue === 'string') {
      return fieldValue.toLowerCase().includes(categoryValue.toLowerCase());
    }
    return categoryValue === rowData[String(field)];
  });

  const totalVolumes = dataFilteredByCategory.reduce<Volume>((acc, sale) => {
    const updatedAcc = { ...acc };
    const _modelledForecast = getEntity('modelledForecast', sale.saleGUID);
    if (!_modelledForecast) return updatedAcc;
    const modelledForecast = _modelledForecast as unknown as ModelledForecastEntity;

    Object.keys(modelledForecast.volume).forEach(key => {
      const year = parseInt(key);
      const volumeValue = modelledForecast.volume[`${year}`];

      if (volumeValue != null && !isNaN(volumeValue)) {
        if (updatedAcc[`${year}`] != null) {
          updatedAcc[`${year}`] += volumeValue;
        } else {
          updatedAcc[`${year}`] = volumeValue;
        }
      }
    });
    return updatedAcc;
  }, {});

  return totalVolumes;
};
