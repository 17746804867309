import { NO_DISTRIBUTOR_GUID } from 'src/constants';
import type { DenormalizedBrandLine } from 'src/records/types/DenormalizedBrandLine';
import type { BrandLine, Distributor, Sale, SaleApi, YearData } from '../shared';
import { newGUID } from './appUtils';
import { getNewPriceValue } from './brandSalesSettersGetters/priceValues';
import { parseVolume } from './brandSalesSettersGetters/volumeValues';

export const OTHER_BRANDLINE_INDICATOR = 'other ';

export enum ModalState {
  Step1 = 'Step1',
  Step2 = 'Step2',
  Create = 'Create',
  CreateBrandLine = 'CreateBrandLine',
}

export enum ModalStateChange {
  BrandLineAdd = 'brandLineAdd',
  ModalVisible = 'modalVisible',
}

export interface FormValue {
  value: string;
  touched: boolean;
  isValid: boolean;
  message: string;
}

interface FormValueNumber {
  value: number;
  touched: boolean;
  isValid: boolean;
  message: string;
}

export interface BrandLineFormItem {
  price: FormValue;
  volume: FormValue;
  containerSize: FormValue;
  localOrImported: FormValue;
  distributorGUID: FormValue;
  priceBandId: FormValue;
  extra1: FormValue;
  extra1Order: FormValueNumber;
  extra2: FormValue;
  extra2Order: FormValueNumber;
}

interface PriceBand {
  name: string;
  displayOrder: number;
  id: number;
  priceBandId?: number;
}

const makeYearData = (
  volume: string,
  price: string,
  containerSize: string,
  currentYear: string
) => {
  const yearData: YearData = {};

  if (volume) {
    // @ts-expect-error: legacy code
    yearData.volume = parseVolume(volume, undefined) as number | undefined;
  }

  if (price) {
    const priceValue = getNewPriceValue(currentYear, price);
    if (priceValue.price) yearData.price = priceValue.price;
    yearData.isPriceEstimated = priceValue.isPriceEstimated;
  }

  if (containerSize) yearData.containerSize = Number.parseFloat(containerSize);

  return yearData;
};

interface MapBrandLineToSaleParams {
  brandLine: BrandLine | DenormalizedBrandLine;
  currentYear: number;
  countryId: number;
  distributors: Distributor[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  newDistributors: any[];
  priceBands: PriceBand[];
  distributorGUID: string;
  priceBandId: string;
  localOrImported: string;
  volume: string;
  price: string;
  containerSize: string;
}

export interface MapBrandLineToSaleAPIParams {
  brandLineGUID: string;
  currentYear: number;
  countryId: number;
  distributorGUID: string;
  priceBandId: string;
  localOrImported: boolean;
  volume: string | number | undefined;
  containerSize: string | number | undefined;
  price: number | string | undefined; // string has to be included to cater for estimated price
}

export const mapBrandLineToSaleNewApi = (params: MapBrandLineToSaleAPIParams): SaleApi => {
  const {
    brandLineGUID,
    currentYear,
    countryId,
    distributorGUID,
    priceBandId,
    localOrImported,
    volume,
    price,
    containerSize,
  } = params;

  const getPrice = (priceValue: string): { value: number; isEstimated: boolean } => {
    const isEstimated = priceValue.startsWith('~') || priceValue.startsWith('--');
    const stringValue = isEstimated ? priceValue.replace('~', '').replace('--', '') : priceValue;
    const numberValue = parseFloat(stringValue.replace(/,/g, ''));

    return { value: numberValue, isEstimated };
  };

  const sale: SaleApi = {
    saleGUID: newGUID(),
    countryId,
    brandLineGUID,
    distributorGUID,
    priceBandId: Number(priceBandId),
    isLocal: localOrImported,
    volume:
      typeof volume !== 'undefined' && volume !== ''
        ? {
            [currentYear]: parseFloat(volume.toString().replace(/,/g, '')),
          }
        : {},
    price:
      typeof price !== 'undefined' && price !== ''
        ? {
            [currentYear]: getPrice(price.toString()),
          }
        : {},
    containerSize: containerSize
      ? {
          [currentYear]: parseFloat(containerSize.toString().replace(/,/g, '')),
        }
      : {},
    acknowledgedByResearcher: volume !== 'undefined',
  };
  return sale;
};

export const mapBrandLineToSale = (params: MapBrandLineToSaleParams): Sale => {
  const {
    brandLine,
    currentYear,
    countryId,
    distributors,
    distributorGUID,
    newDistributors,
    priceBands,
    priceBandId,
    localOrImported,
    volume,
    price,
    containerSize,
  } = params;

  const sale: Sale = {
    saleGUID: newGUID(),
    countryId,

    category1Id: 0,
    category2Id: 0,
    category3Id: 0,
    category4Id: 0,
    category5Id: brandLine.category5Id,

    category1Name: '',
    category2Name: '',
    category3Name: '',
    category4Name: '',
    category5Name: '',

    category1DisplayOrder: 0,
    category2DisplayOrder: 0,
    category3DisplayOrder: 0,
    category4DisplayOrder: 0,
    category5DisplayOrder: 0,

    // remove possible new# prefix added for brandline table
    // remove the unique suffix added in the reducer to appease react keys
    // @ts-expect-error: legacy code
    brandLineGUID: brandLine.brandLineGUID.replace(/^new#/, '').split('.')[0],
    brandLineName: brandLine.brandLineName,
    brandLineDisplayName: brandLine.brandLineDisplayName,

    // remove possible new# prefix added for brandline table
    brandGUID: brandLine.brandGUID.replace(/^new#/, ''),
    brandName: brandLine.brandName,

    originId: brandLine.originId,
    originName: brandLine.originName,

    // remove possible new# prefix added for brandline table
    ownerGUID: brandLine.ownerGUID ? brandLine.ownerGUID.replace(/^new#/, '') : '',
    ownerName: brandLine.ownerName,
    createdIn: brandLine.createdIn ?? 0,

    extra1Order: 0, // TODO - this will change when extra suggestion comes in
    extra2Order: 0,

    forecast: false,

    // These will be updated below
    distributorGUID,
    distributorName: '',

    //  Brand Line Attributes
    isFlavoured: brandLine.isFlavoured,

    alcoholicStrengthId: brandLine.alcoholicStrengthId,
    alcoholicStrengthBand: brandLine.alcoholicStrengthBand,

    isCraft: brandLine.isCraft,

    isBLE: brandLine.isBLE,
    bleName: brandLine.bleName,

    ageId: brandLine.ageId,
    ageStatement: brandLine.ageStatement,

    maltRegionId: brandLine.maltRegionId,
    maltRegionName: brandLine.maltRegionName,

    ageInYears: brandLine.ageInYears,

    priceBandId: 0,
    priceBandName: '',
    priceBandDisplayOrder: 0,
    localOrImported: '',
    yearData: {},
  };

  const distPredicate = (distributor: Distributor) =>
    distributor.distributorGUID === distributorGUID;
  const selectedDistributor: Distributor | undefined = distributors.find(distPredicate);
  if (selectedDistributor) sale.distributorName = selectedDistributor.distributorName;

  /* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any */
  const newDistPredictate = (distItem: any) => distItem.value === distributorGUID;
  /* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any */
  const selectedNewDistributor: any = newDistributors.find(newDistPredictate);
  /* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access */
  if (selectedNewDistributor) sale.distributorName = selectedNewDistributor.label;

  //  FIXME:  Don't force priceBandId to number
  sale.priceBandId = Number(priceBandId);
  //  FIXME:  Don't force priceBand.id to string - by not forcing it to a string,
  //          the price name is not picked up during adding a new brand sale
  const priceBandPredicate = (priceBand: PriceBand) =>
    priceBand.priceBandId?.toString() === priceBandId;
  const selectedPriceBand: PriceBand | undefined = priceBands.find(priceBandPredicate);
  if (selectedPriceBand) {
    sale.priceBandName = selectedPriceBand.name;
    sale.priceBandDisplayOrder = selectedPriceBand.displayOrder;
  }

  sale.localOrImported = localOrImported;

  const yearData = makeYearData(volume, price, containerSize, currentYear.toString());
  if (Object.entries(yearData).length !== 0) sale.yearData[currentYear] = yearData;

  return sale;
};

const emptyValue: FormValue = {
  value: '',
  isValid: true,
  touched: false,
  message: '',
};

export const makeEmptyItem = () => {
  const item: BrandLineFormItem = {
    price: { ...emptyValue },
    volume: { ...emptyValue },
    containerSize: { ...emptyValue },
    localOrImported: { ...emptyValue },
    distributorGUID: { ...emptyValue, value: NO_DISTRIBUTOR_GUID },
    priceBandId: { ...emptyValue },
    extra1: { ...emptyValue },
    extra1Order: { ...emptyValue, value: 0 },
    extra2: { ...emptyValue },
    extra2Order: { ...emptyValue, value: 0 },
  };
  return item;
};

export const updateDistributorIdFormStateWithDefault = (
  formItem: BrandLineFormItem,
  brandLine: { ownerGUID: string },
  isOther: boolean,
  sales?: Sale[]
): undefined | BrandLineFormItem => {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (!formItem || !brandLine || isOther) return undefined;

  const result = { ...formItem };

  if (
    !formItem.distributorGUID.value ||
    (formItem.distributorGUID.value === NO_DISTRIBUTOR_GUID && !formItem.distributorGUID.touched)
  ) {
    const salesWithMatchingOwnerId = (sales ?? []).filter(
      (i: Sale) =>
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        i && i.ownerGUID === brandLine.ownerGUID && i.distributorGUID !== NO_DISTRIBUTOR_GUID
    );

    if (salesWithMatchingOwnerId.length === 0) {
      result.distributorGUID.value = NO_DISTRIBUTOR_GUID;
      result.distributorGUID.touched = true;
      return result;
    }

    const distributorCount = salesWithMatchingOwnerId.reduce<Record<string, number>>(
      /* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any */
      (acc: any, i: any) => {
        /* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/restrict-plus-operands, @typescript-eslint/no-unsafe-member-access */
        acc[i.distributorGUID] = acc[i.distributorGUID] + 1 || 1;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return acc;
      },
      {}
    );

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const distributorsOrderedByCount = Object.entries(distributorCount).sort(
      /* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any */
      (a: any, b: any) => b[1] - a[1]
    );

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition, @typescript-eslint/prefer-nullish-coalescing
    const firstDistributorCount = distributorsOrderedByCount?.[0]?.[1] || 0;
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition, @typescript-eslint/prefer-nullish-coalescing
    const secondDistributorCount = distributorsOrderedByCount?.[1]?.[1] || 0;

    // only update distributorGUID if there is a single distributor that has the max count
    if (firstDistributorCount > secondDistributorCount) {
      // @ts-expect-error: legacy code
      [[result.distributorGUID.value]] = distributorsOrderedByCount;
      result.distributorGUID.touched = true;
    }
  }

  return result;
};
