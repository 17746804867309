// @TODO - COLLECTED_YEAR should really come from the API (one source of truth)
// Note that COLLECTED_YEAR is also used in ExportReport
export const COLLECTED_YEAR = 2024;
export const FORECASTING_YEARS_MODIFIER = 5;

export const DEFAULT_FIRST_YEAR = 2000;
export const DEFAULT_NUMBER_OF_YEARS_TO_SHOW = 3;

export const NO_DISTRIBUTOR_GUID = 'AAAAAAAA-AAAA-AAAA-AAAA-AAAAAAAAAAAA';

export const UNASSIGNED_PRICE_BAND_ID = 34;
