//  TODO:   **** ALL TODO's IN THIS FILE ARE FROM ISOTOMA
import * as t from 'io-ts';

export enum CellValueType {
  string = 'string',
  number = 'number',
}

enum FetchStatus {
  UNDEFINED = 'UNDEFINED',
  LOADING = 'LOADING',
  LOADED = 'LOADED',
  ERROR = 'ERROR',
}

export enum Market {
  domestic = 'domestic',
  df = 'df',
}

export enum BrandLineStatus {
  PENDING = 0,
  APPROVED = 1,
  REJECTED = 2,
  REFERED = 3,
}

export enum BrandLineTypes {
  BRANDED = 0,
  OTHER = 1,
  FORECAST = 2,
  PRIVATE_LABEL = 3,
}

// { lookUpKey: lookUpVal }
const LookUp = t.record(t.string, t.string);
export type LookUp = t.TypeOf<typeof LookUp>;

// Context

const PriceFormat = t.partial({
  decimalPlaces: t.number,
});
type PriceFormat = t.TypeOf<typeof PriceFormat>;

const YearPriceFormat = t.record(t.string, PriceFormat);
export type YearPriceFormat = t.TypeOf<typeof YearPriceFormat>;

const PriceBandLimits = t.record(t.string, t.number);
type PriceBandLimits = t.TypeOf<typeof PriceBandLimits>;

const AreaRegion = t.type({
  id: t.number,
  name: t.string,
});
type AreaRegion = t.TypeOf<typeof AreaRegion>;

const CountryRequired = t.type({
  name: t.string,
  id: t.string,
  demonym: t.string,
});

const CountryOptional = t.partial({
  isDomestic: t.boolean,
  standardSize: t.number,
  area: AreaRegion,
  region: AreaRegion,
  priceFormat: YearPriceFormat,
  priceBandLimits: PriceBandLimits,
  priceBandLimitsStillWine: PriceBandLimits,
  priceBandLimitsChampagne: PriceBandLimits,
});

const Country = t.intersection([CountryRequired, CountryOptional]);
export type Country = t.TypeOf<typeof Country>;

const CountryList = t.array(Country);
export type CountryList = t.TypeOf<typeof CountryList>;

// Brand Sales

export enum YearDataTypes {
  volume = 'volume',
  price = 'price',
  isPriceEstimated = 'isPriceEstimated',
  containerSize = 'containerSize',
  onPremisePercent = 'onPremisePercent',
  externalDataIsUsed = 'externalDataIsUsed',
  externalDataComment = 'externalDataComment',
  externalDataVolume = 'externalDataVolume',
  volumeReadOnly = 'volumeReadOnly',
}

const YearData = t.partial({
  volume: t.number,
  price: t.number,
  isPriceEstimated: t.boolean,
  containerSize: t.number,
  onPremisePercent: t.number,
  externalDataIsUsed: t.boolean,
  externalDataComment: t.string,
  externalDataVolume: t.number,
  volumeReadOnly: t.number,
  previousVolume: t.number,
  previousPrice: t.number,
  previousIsPriceEstimated: t.boolean,
  previousContainerSize: t.number,
  previousOnPremisePercent: t.number,
  quote: t.string,
  retailValue: t.number,
  previousRetailValue: t.number,
});
export type YearData = t.TypeOf<typeof YearData>;

const SaleRequired = t.type({
  saleGUID: t.string,
  countryId: t.number,

  brandLineGUID: t.string,
  brandLineName: t.string,
  brandLineDisplayName: t.string,

  brandGUID: t.string,
  brandName: t.string,

  sectionId: t.string,
  sectionName: t.string,

  priceBandId: t.number,
  priceBandName: t.string,
  priceBandDisplayOrder: t.number,

  originId: t.number,
  originName: t.string,
  originDisplayOrder: t.number,

  ownerGUID: t.string,
  ownerName: t.string,

  distributorGUID: t.string,
  distributorName: t.string,

  isFlavoured: t.boolean,
  flavouredName: t.string,

  isLocal: t.boolean,

  alcoholicStrengthId: t.number,
  alcoholicStrengthBand: t.string,

  isCraft: t.boolean,
  craftName: t.string,

  isBLE: t.boolean,
  bleName: t.string,

  isRTS: t.boolean,

  ageId: t.number,
  ageStatement: t.string,

  maltRegionId: t.number,
  maltRegionName: t.string,

  ageInYears: t.number,

  localOrImported: t.string,

  category1Id: t.number,
  category2Id: t.number,
  category3Id: t.number,
  category4Id: t.number,
  category5Id: t.number,

  category1Name: t.string,
  category2Name: t.string,
  category3Name: t.string,
  category4Name: t.string,
  category5Name: t.string,

  category1DisplayOrder: t.number,
  category2DisplayOrder: t.number,
  category3DisplayOrder: t.number,
  category4DisplayOrder: t.number,
  category5DisplayOrder: t.number,

  forecast: t.boolean,

  yearData: t.record(t.string, YearData),
});

const SaleOptional = t.partial({
  isOther: t.boolean,
  oneYearChange: t.string,
  fourYearsCagr: t.string,
  lastUpdated: t.string, // TODO - rationalise this with Timestamp
  lastUpdatedBy: t.string,
  lastUpdatedEmail: t.string,
  lastUpdatedTimestamp: t.number,
  previousCycleYearData: t.record(t.string, YearData),
  isDelete: t.union([t.boolean, t.null]),
  createdIn: t.number,
});

const Sale = t.intersection([SaleRequired, SaleOptional]);
export type Sale = t.TypeOf<typeof Sale>;

const PriceEstimated = t.type({
  value: t.number,
  isEstimated: t.boolean,
});

type PriceEstimated = t.TypeOf<typeof PriceEstimated>;

const SaleApi = t.type({
  saleGUID: t.string,
  countryId: t.number,
  brandLineGUID: t.string,
  priceBandId: t.number,
  distributorGUID: t.string,
  isLocal: t.boolean,
  volume: t.record(t.number, t.number),
  containerSize: t.record(t.number, t.number),
  price: t.record(t.number, PriceEstimated),
  acknowledgedByResearcher: t.boolean,
});
export type SaleApi = t.TypeOf<typeof SaleApi>;

const SaleList = t.array(Sale);
export type SaleList = t.TypeOf<typeof SaleList>;

// Brand lines

const BrandLineRequired = t.type({
  brandLineGUID: t.string,
  brandLineName: t.string,
  brandLineDisplayName: t.string,

  brandGUID: t.string,
  brandName: t.string,

  ownerGUID: t.string,
  ownerName: t.string,

  originId: t.number,
  originName: t.string,
  originDisplayOrder: t.number,

  brandLineTypeId: t.number,

  isFlavoured: t.boolean,
  flavouredName: t.string,

  alcoholicStrengthId: t.number,
  alcoholicStrengthBand: t.string,

  isCraft: t.boolean,
  craftName: t.string,

  isBLE: t.boolean,
  bleName: t.string,

  isRTS: t.boolean,

  ageId: t.number,
  ageStatement: t.string,

  maltRegionId: t.number,
  maltRegionName: t.string,

  ageInYears: t.number,

  category5Id: t.number,
  category5Name: t.string,

  reportingCategory: t.boolean,
  status: t.number,
});

const BrandLineOptional = t.partial({
  isDelete: t.union([t.boolean, t.null]),
  saleGUID: t.union([t.string, t.undefined]),
  countryId: t.union([t.number, t.undefined]),
  distributorGUID: t.union([t.string, t.undefined]),
  distributorName: t.union([t.string, t.undefined]),
  createdBy: t.union([t.string, t.undefined]),
  createdIn: t.union([t.number, t.undefined]),
  approval: t.union([t.string, t.undefined]),
  reason: t.union([t.string, t.undefined]),
  usualPriceBandId: t.union([t.number, t.undefined]),
  category1Id: t.number,
});

// TODO: Legacy! Rework Redux store not to use this but rather DenormalizedBrandLine
const BrandLine = t.intersection([BrandLineRequired, BrandLineOptional]);
export type BrandLine = t.TypeOf<typeof BrandLine>;

const BrandLineList = t.array(BrandLine);
export type BrandLineList = t.TypeOf<typeof BrandLineList>;

// Additional Wine

const AdditionalWineRequired = t.type({
  id: t.string,
  countryId: t.number,
  segment: t.string,
  volumes: t.record(t.string, t.number),
});

const AdditionalWineOptional = t.partial({
  colourName: t.string,
  wineVarietal: t.string,
  originId: t.number,
  originName: t.string,
  originDisplayOrder: t.number,
  heading: t.string,
  isOrganic: t.boolean,
});

const AdditionalWine = t.intersection([AdditionalWineRequired, AdditionalWineOptional]);
type AdditionalWine = t.TypeOf<typeof AdditionalWine>;

const AdditionalWineList = t.array(AdditionalWine);
export type AdditionalWineList = t.TypeOf<typeof AdditionalWineList>;

// E-commerce

const EcommerceRequired = t.type({
  id: t.string,
  countryId: t.number,
  category1Id: t.number,
  category1Name: t.string,
  category1DisplayOrder: t.number,
  category2Id: t.number,
  category2Name: t.string,
  category2DisplayOrder: t.number,
});

const RetailValues = t.record(t.string, t.number);
type RetailValues = t.TypeOf<typeof RetailValues>;

const EcommerceOptional = t.partial({
  retailValues: RetailValues,
});

const Ecommerce = t.intersection([EcommerceRequired, EcommerceOptional]);
export type Ecommerce = t.TypeOf<typeof Ecommerce>;

const EcommerceList = t.array(Ecommerce);
export type EcommerceList = t.TypeOf<typeof EcommerceList>;

// Views

const DefinedGroup = t.type({
  columns: t.array(t.string),
});
export type DefinedGroup = t.TypeOf<typeof DefinedGroup>;

const ConditionalGroup = t.type({
  condition: t.string,
  values: t.array(t.string),
  passColumn: t.string,
  failColumn: t.string,
});
export type ConditionalGroup = t.TypeOf<typeof ConditionalGroup>;

const ViewRowGroup = t.union([DefinedGroup, ConditionalGroup]);
type ViewRowGroup = t.TypeOf<typeof ViewRowGroup>;

export const ViewRowGroupList = t.array(ViewRowGroup);
type ViewRowGroupList = t.TypeOf<typeof ViewRowGroupList>;

export const Filters = t.type({
  values: t.array(t.string),
});

// { columnId: { values: string[] } }
export const FilterModel = t.record(t.string, Filters);
export type FilterModel = t.TypeOf<typeof FilterModel>;

const ViewRequired = t.type({
  id: t.string,
  viewTypeId: t.string,
  viewRowGroups: ViewRowGroupList,
  viewDisplayName: t.string,
  viewType: t.string,
});
type ViewRequired = t.TypeOf<typeof ViewRequired>;

const SectionViewRequired = t.type({
  countryId: t.string,
  sectionId: t.string,
  sectionName: t.string,
  viewTypeOrder: t.string,
  viewFilters: FilterModel,
});
type SectionViewRequired = t.TypeOf<typeof SectionViewRequired>;

const View = t.intersection([ViewRequired, SectionViewRequired]);
export type View = t.TypeOf<typeof View>;

export const ViewList = t.array(View);
export type ViewList = t.TypeOf<typeof ViewList>;

const DefaultView = ViewRequired;
export type DefaultView = t.TypeOf<typeof DefaultView>;

// Sections

const SectionRequired = t.type({
  sectionId: t.string,
  sectionName: t.string,
  category1Id: t.number,
  isSubSection: t.boolean,
});
type SectionRequired = t.TypeOf<typeof SectionRequired>;

const SectionOptional = t.partial({
  subSections: t.array(SectionRequired),
});
type SectionOptional = t.TypeOf<typeof SectionOptional>;

export const Section = t.intersection([SectionRequired, SectionOptional]);
export type Section = t.TypeOf<typeof Section>;
export type SubSection = t.TypeOf<typeof SectionRequired>;

export const SectionList = t.array(Section);
export type SectionList = t.TypeOf<typeof SectionList>;

// Distributors

export const Distributor = t.type({
  countryId: t.number,
  distributorGUID: t.string,
  distributorName: t.string,
  reportingCategory: t.boolean,
});
export type Distributor = t.TypeOf<typeof Distributor>;

export const DistributorList = t.array(Distributor);
export type DistributorList = t.TypeOf<typeof DistributorList>;

// Price bands

export const PriceBand = t.type({
  id: t.number,
  name: t.string,
  displayOrder: t.number,
});
export type PriceBand = t.TypeOf<typeof PriceBand>;

export const PriceBandList = t.array(PriceBand);
export type PriceBandList = t.TypeOf<typeof PriceBandList>;

// MarkedForecast
export const MarkedForecast = t.type({
  id: t.string,
  countryId: t.string,
  isMarked: t.boolean,
});
export type MarkedForecast = t.TypeOf<typeof MarkedForecast>;

// Chunks

export const Owner = t.type({
  sub: t.string,
  name: t.string,
});
export type Owner = t.TypeOf<typeof Owner>;

const ChunkRequired = t.type({
  market: t.string,
  regionId: t.string,
  countryId: t.string,
  category1Id: t.string,
});
const ChunkOptional = t.partial({
  regionName: t.string,
  countryName: t.string,
  category1Name: t.string,
  owners: t.array(Owner),
  progress: t.number,
  status: t.string, // TODO - add an enum
});

export const Chunk = t.intersection([ChunkRequired, ChunkOptional]);
export type Chunk = t.TypeOf<typeof Chunk>;

export const ChunkList = t.array(Chunk);
export type ChunkList = t.TypeOf<typeof ChunkList>;

// Data

const DataItem = t.partial({
  status: t.keyof(FetchStatus),
  error: t.string,
});
export type DataItem = t.TypeOf<typeof DataItem>;

const BrandRequired = t.type({
  brandGUID: t.string,
  brandName: t.string,
  displayName: t.string,
  ownerGUID: t.string,
});
const BrandOptional = t.partial({
  isDelete: t.union([t.boolean, t.null]),
  isApproved: t.union([t.number, t.undefined]),
  countryId: t.union([t.number, t.undefined]),
  addedTimestamp: t.union([t.number, t.undefined]),
  addedBy: t.union([t.number, t.undefined]),
});
export const Brand = t.intersection([BrandRequired, BrandOptional]);
const BrandApiRequired = t.type({
  brandGUID: t.string,
  brandName: t.string,
  brandDisplayName: t.string,
  ownerGUID: t.string,
  createdIn: t.number,
});
export const BrandApi = t.intersection([BrandApiRequired, BrandOptional]);
export type Brand = t.TypeOf<typeof Brand>;
export type BrandApi = t.TypeOf<typeof BrandApi>;

export const BrandList = t.array(Brand);

const BrandOwnerRequired = t.type({
  ownerGUID: t.string,
  ownerName: t.string,
});
const BrandOwnerApiRequired = t.type({
  ownerGUID: t.string,
  ownerName: t.string,
  createdIn: t.number,
});
const BrandOwnerOptional = t.partial({
  isDelete: t.union([t.boolean, t.null]),
  countryId: t.union([t.number, t.null]),
  isApproved: t.union([t.number, t.undefined]),
  addedTimestamp: t.union([t.number, t.undefined]),
  addedBy: t.union([t.number, t.undefined]),
});

const BrandOwner = t.intersection([BrandOwnerRequired, BrandOwnerOptional]);
export type BrandOwner = t.TypeOf<typeof BrandOwner>;

const BrandOwnerApi = t.intersection([BrandOwnerApiRequired, BrandOwnerOptional]);
export type BrandOwnerApi = t.TypeOf<typeof BrandOwnerApi>;

const BrandOwnerList = t.array(BrandOwner);
export type BrandOwnerList = t.TypeOf<typeof BrandOwnerList>;

const Origin = t.type({
  originId: t.string,
  originName: t.string,
  isNewWorld: t.boolean,
  displayOrder: t.number,
});
export type Origin = t.TypeOf<typeof Origin>;

const OriginList = t.array(Origin);
export type OriginList = t.TypeOf<typeof OriginList>;
