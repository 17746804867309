import type { Options, SeriesLineOptions, SeriesOptionsType } from 'highcharts';

import type { ChartMenuOptionValue } from 'src/components/ForecastChartMenu';
import { FORECASTING_YEARS_MODIFIER } from 'src/constants';
import type { DenormalizedBrandSales } from 'src/records/types/DenormalizedBrandSales';
import type { Volume } from 'src/types/Entity';
import { type ModelledForecastEntity } from 'src/types/entity/ModelledForecast';
import { getArrayFromLength, getArrayFromRange } from 'src/utils/array';
import { getSuffixedForecastYear, isForecastYearColumn } from 'src/views/utils/forecasting/grid';
import type { SelectedCategoriesProps } from '../Chart';

type Forecast =
  | 'forecast'
  | 'historical'
  | 'modelledForecast'
  | 'overwriteSecondAxis'
  | 'previousYearsForecast';

export const getAxisCategoriesYears = (currentYear: number): string[] => {
  const yearStart = currentYear - 4;
  const yearEnd = currentYear + FORECASTING_YEARS_MODIFIER;

  // + 1 to account for the currentYear
  const yearRange = yearEnd - yearStart + 1;
  const yearList = getArrayFromLength(yearRange);
  return yearList.map((_, index) => {
    const year = yearStart + index;

    if (isForecastYearColumn({ headerName: String(year) })) {
      return getSuffixedForecastYear(year);
    }

    return `${year}`;
  });
};

type ISeriesLookUp = Record<Forecast, SeriesLineOptions>;

const seriesLookUp: ISeriesLookUp = {
  historical: {
    name: 'Historical',
    type: 'line',
    color: '#B4BBC1',
    yAxis: 0,
    zones: [{ value: 0 }, { dashStyle: 'ShortDash' }],
    showInLegend: true,
    data: [],
    marker: {
      symbol: 'circle',
    },
  },
  modelledForecast: {
    name: 'Modelled',
    type: 'line',
    color: '#32CD32',
    yAxis: 0,
    legendIndex: 1,
    zones: [{ value: 0 }, { dashStyle: 'LongDash' }],
    showInLegend: true,
    data: [],
    marker: {
      symbol: 'square',
    },
  },
  previousYearsForecast: {
    name: 'Previous',
    type: 'line',
    color: '#B4BBC1',
    yAxis: 0,
    legendIndex: 2,
    zones: [{ value: 0 }, { dashStyle: 'LongDash' }],
    showInLegend: true,
    data: [],
    marker: {
      symbol: 'square',
    },
  },
  forecast: {
    name: 'Forecast',
    type: 'line',
    color: '#426CCF',
    yAxis: 0,
    zones: [{ value: 0 }, { dashStyle: 'LongDash' }],
    legendIndex: 3,
    showInLegend: true,
    data: [],
    marker: {
      symbol: 'diamond',
    },
  },
  overwriteSecondAxis: {
    name: 'All',
    type: 'line',
    color: '#FAA62F',
    yAxis: 1,
    legendIndex: 4,
    zones: [{ value: 0 }, { dashStyle: 'LongDash' }],
    showInLegend: true,
    data: [],
    marker: {
      symbol: 'triangle',
    },
  },
};

const summedVolume = (dataToSum: DenormalizedBrandSales[]) => {
  return dataToSum
    .map(el => el.volume)
    .reduce<Volume>((acc, dataSet) => {
      Object.keys(dataSet).forEach(year => {
        if (!Number.isNaN(dataSet[Number(year)]) && dataSet[Number(year)]) {
          // eslint-disable-next-line no-param-reassign
          acc[Number(year)] = (dataSet[Number(year)] ?? 0) + (acc[Number(year)] ?? 0);
        }
      });
      return acc;
    }, {});
};

const getChartSeries = (
  volume: Volume,
  type: Forecast,
  currentYear: number,
  secondAxisName: string
): SeriesOptionsType => {
  const minYear = currentYear - 4;
  const maxYear = currentYear + FORECASTING_YEARS_MODIFIER;

  if (type === 'historical') {
    const data = getArrayFromRange(minYear, maxYear).map(year => {
      const volumeForYear = volume[year];
      if (!volumeForYear || year > currentYear) return null;
      return volumeForYear;
    });

    return { ...seriesLookUp[type], data };
  }

  if (type === 'forecast' || type === 'modelledForecast' || type === 'previousYearsForecast') {
    const data = getArrayFromRange(minYear, maxYear).map(year => {
      const volumeForYear = volume[year];
      if (!volumeForYear || year < currentYear) return null;
      return volumeForYear;
    });

    return { ...seriesLookUp[type], data };
  }

  // type mut be 'overwriteSecondAxis'
  const data = getArrayFromRange(minYear, maxYear).map(year => {
    const volumeForYear = volume[year];
    if (!volumeForYear) return null;
    return volumeForYear;
  });

  return {
    ...seriesLookUp[type],
    name: secondAxisName,
    data,
  };
};

const getDefaultChartSeries = (
  currentYear: number,
  showCurrentYearForecast: boolean,
  showModelledForecast: boolean,
  showPreviousForecast: boolean,
  forecastTotals: Volume | undefined,
  modelledForecastTotals: Volume | undefined,
  previousForecastTotals: Volume | undefined
): SeriesOptionsType[] => {
  if (!forecastTotals) {
    return [];
  }
  const chartData: SeriesOptionsType[] = [];

  chartData.push(getChartSeries(forecastTotals, 'historical', currentYear, ''));

  if (showCurrentYearForecast)
    chartData.push(getChartSeries(forecastTotals, 'forecast', currentYear, ''));

  if (showModelledForecast && modelledForecastTotals)
    chartData.push(getChartSeries(modelledForecastTotals, 'modelledForecast', currentYear, ''));

  if (showPreviousForecast && previousForecastTotals)
    chartData.push(
      getChartSeries(previousForecastTotals, 'previousYearsForecast', currentYear, '')
    );

  return chartData;
};

const getSecondAxisLabel = (secondAxisField: string, rowData: DenormalizedBrandSales): string => {
  switch (secondAxisField) {
    case 'category1':
    case 'category2':
    case 'category3':
    case 'category4':
    case 'category5': {
      const fieldName = `category${secondAxisField.substring(
        secondAxisField.length - 1,
        secondAxisField.length
      )}Name` as keyof DenormalizedBrandSales;
      return String(rowData[fieldName]);
    }

    case 'origin': {
      return String(rowData.originName);
    }

    case 'localOrImported': {
      return rowData.isLocal ? 'Local' : 'Imported';
    }
  }
  return '';
};

type BrandSaleAxisKey = ReturnType<typeof getBrandSaleKeyFromAxisField>;

const getBrandSaleKeyFromAxisField = (field: ChartMenuOptionValue | undefined) => {
  switch (field) {
    case 'category1':
    case 'category2':
    case 'category3':
    case 'category4':
    case 'category5':
      return `${field}Id` as const;
    case 'localOrImported':
      return 'isLocal';
    case 'origin':
      return 'originId';
    default:
      return field as '';
  }
};

const getPreviousForecastVolumeForSelectedRow = (
  previousForecasts: DenormalizedBrandSales[],
  selectedRow: DenormalizedBrandSales
) => {
  return previousForecasts.find(
    itemRow =>
      itemRow.brandLineGUID === selectedRow.brandLineGUID &&
      itemRow.priceBandId === selectedRow.priceBandId &&
      itemRow.isLocal === selectedRow.isLocal
  )?.volume;
};

const getSelectedRowChartSeries = (
  forecasts: DenormalizedBrandSales[],
  modelledForecastData: ModelledForecastEntity[],
  previousForecastData: DenormalizedBrandSales[],
  currentYear: number,
  showCurrentYearForecast: boolean,
  showModelledForecast: boolean,
  showPreviousForecast: boolean,
  showSecondAxis: boolean,
  secondAxisField: ChartMenuOptionValue,
  selectedRow: DenormalizedBrandSales
) => {
  const chartData = [];

  const historicalData: Volume = {};
  const overwriteData: Volume = {};
  const modelledForecastForSelectedRow = modelledForecastData.find(
    ({ saleGUID }) => saleGUID === selectedRow.saleGUID
  )?.volume;
  const previousData = getPreviousForecastVolumeForSelectedRow(previousForecastData, selectedRow);

  Object.keys(selectedRow.volume).forEach(key => {
    const year = parseInt(key);
    const volumeForYear = selectedRow.volume[year];
    if (volumeForYear && year <= currentYear) {
      historicalData[year] = volumeForYear;
    }
    if (volumeForYear && year >= currentYear) {
      if (year === currentYear && selectedRow.volume[year + 1] == null) {
        return;
      }

      overwriteData[year] = volumeForYear;
    }
  });

  chartData.push(getChartSeries(historicalData, 'historical', currentYear, ''));

  if (showCurrentYearForecast)
    chartData.push(getChartSeries(overwriteData, 'forecast', currentYear, ''));

  if (showModelledForecast && modelledForecastForSelectedRow)
    chartData.push(
      getChartSeries(modelledForecastForSelectedRow, 'modelledForecast', currentYear, '')
    );

  if (showPreviousForecast && previousData)
    chartData.push(getChartSeries(previousData, 'previousYearsForecast', currentYear, ''));

  if (showSecondAxis) {
    const filterField = getBrandSaleKeyFromAxisField(secondAxisField);
    let filteredData: DenormalizedBrandSales[] = [];

    if (filterField) {
      if (filterField !== 'isLocal' && filterField !== 'originId') {
        filteredData = forecasts.filter(sale => sale[filterField] === selectedRow[filterField]);
      }
      if (filterField === 'isLocal' || filterField === 'originId') {
        filteredData = forecasts.filter(
          sale =>
            sale[filterField] === selectedRow[filterField] &&
            sale.category5Id === selectedRow.category5Id
        );
      }

      const filteredChartData = summedVolume(filteredData);

      chartData.push(
        getChartSeries(
          filteredChartData,
          'overwriteSecondAxis',
          currentYear,
          getSecondAxisLabel(secondAxisField, selectedRow)
        )
      );
    }
  }

  return chartData;
};

interface ChartTitleParams {
  saleRow: DenormalizedBrandSales | undefined;
  selectedCategories: SelectedCategoriesProps | undefined;
  viewName: string;
}

export const getChartTitle = ({ saleRow, selectedCategories, viewName }: ChartTitleParams) => {
  if (saleRow) {
    let chartTitle = saleRow.isLocal ? 'Local' : 'Imported';
    if (selectedCategories?.isForecastByOrigin) {
      chartTitle = saleRow.originName;
    }
    return `${saleRow.category5Name} - ${chartTitle} - ${saleRow.priceBandName as string}`;
  }

  return viewName;
};

interface GetChartSeriesOptions {
  currentYear: number;
  forecasts: DenormalizedBrandSales[];
  forecastTotals: Volume | undefined;
  modelledForecastData: ModelledForecastEntity[];
  modelledForecastTotals: Volume | undefined;
  previousForecastData: DenormalizedBrandSales[];
  previousForecastTotals: Volume | undefined;
  secondAxisField: ChartMenuOptionValue;
  selectedRowData: DenormalizedBrandSales | undefined;
  showCurrentYearForecast: boolean;
  showModelledForecast: boolean;
  showPreviousForecast: boolean;
  showSecondAxis: boolean;
}

export const getChartSeriesOptions = ({
  currentYear,
  forecasts,
  forecastTotals,
  modelledForecastData,
  modelledForecastTotals,
  previousForecastData,
  previousForecastTotals,
  secondAxisField,
  selectedRowData,
  showCurrentYearForecast,
  showModelledForecast,
  showPreviousForecast,
  showSecondAxis,
}: GetChartSeriesOptions): SeriesOptionsType[] => {
  if (!selectedRowData) {
    return getDefaultChartSeries(
      currentYear,
      showCurrentYearForecast,
      showModelledForecast,
      showPreviousForecast,
      forecastTotals,
      modelledForecastTotals,
      previousForecastTotals
    );
  }

  return forecastTotals
    ? getSelectedRowChartSeries(
        forecasts,
        modelledForecastData,
        previousForecastData,
        currentYear,
        showCurrentYearForecast,
        showModelledForecast,
        showPreviousForecast,
        showSecondAxis,
        secondAxisField,
        selectedRowData
      )
    : [];
};

const getHighestValueFromVolume = (volume: Volume) => {
  return Object.values(volume).reduce((highestVolume, volume) => {
    if (volume > highestVolume) {
      return volume;
    }
    return highestVolume;
  }, 0);
};

const getSummedVolumeFromForecasts = (
  brandSaleKey: BrandSaleAxisKey,
  forecasts: DenormalizedBrandSales[],
  selectedRowData: DenormalizedBrandSales
) => {
  if (brandSaleKey !== '' && brandSaleKey !== 'isLocal' && brandSaleKey !== 'originId') {
    const filteredData = forecasts.filter(
      sale => sale[brandSaleKey] === selectedRowData[brandSaleKey]
    );
    return summedVolume(filteredData);
  }

  const filteredData: DenormalizedBrandSales[] = [];
  return summedVolume(filteredData);
};

interface GetMinMaxVolumeAxisParams {
  forecasts: DenormalizedBrandSales[];
  previousForecastData: DenormalizedBrandSales[];
  showPreviousForecast: boolean;
  forecastTotals: Volume | undefined;
  secondAxisField: ChartMenuOptionValue;
  selectedRowData: DenormalizedBrandSales | undefined;
  showSecondAxis: boolean;
}

export const getMinMaxVolumeAxis = ({
  forecasts,
  previousForecastData,
  showPreviousForecast,
  forecastTotals,
  secondAxisField,
  selectedRowData,
  showSecondAxis,
}: GetMinMaxVolumeAxisParams) => {
  let maxVolumeInFirstAxis = 0;
  let minVolumeInSecondAxis = 0;

  if (forecastTotals) {
    maxVolumeInFirstAxis = getHighestValueFromVolume(forecastTotals);
  }

  if (showSecondAxis && selectedRowData) {
    maxVolumeInFirstAxis = getHighestValueFromVolume(selectedRowData.volume);

    const brandSaleKey = getBrandSaleKeyFromAxisField(secondAxisField);

    if (brandSaleKey) {
      const sumVolume = getSummedVolumeFromForecasts(brandSaleKey, forecasts, selectedRowData);
      minVolumeInSecondAxis = Object.values(sumVolume).reduce((lowestVolume, volume) => {
        const volumeIsLowerOrZero = volume < lowestVolume || lowestVolume === 0;

        if (volumeIsLowerOrZero) {
          return volume;
        }
        return lowestVolume;
      }, 0);
    }
  }

  if (showPreviousForecast && previousForecastData.length && selectedRowData) {
    const previousForecastVolumeData =
      getPreviousForecastVolumeForSelectedRow(previousForecastData, selectedRowData) ?? {};
    const previousForecastsMaxVolume = getHighestValueFromVolume(previousForecastVolumeData);

    if (previousForecastsMaxVolume > maxVolumeInFirstAxis) {
      maxVolumeInFirstAxis = previousForecastsMaxVolume;
    }
  }

  return { minVolumeInSecondAxis, maxVolumeInFirstAxis };
};

interface GetChartOptionsParams {
  axisCategoriesYears: string[];
  chartSeries: SeriesOptionsType[];
  chartTitle: string;
  maxVolumeInFirstAxis: number;
  minVolumeInSecondAxis: number;
  selectedRowData: DenormalizedBrandSales | undefined;
  showSecondAxis: boolean;
  valueDecimals: number;
}

export const getChartOptions = ({
  axisCategoriesYears,
  chartSeries,
  chartTitle,
  maxVolumeInFirstAxis,
  minVolumeInSecondAxis,
  selectedRowData,
  showSecondAxis,
  valueDecimals,
}: GetChartOptionsParams): Options => {
  const options: Options = {
    title: {
      text: chartTitle,
    },
    series: chartSeries,
    credits: {
      enabled: false,
    },
    xAxis: {
      categories: axisCategoriesYears,
    },
    yAxis: [
      {
        opposite: false,
        labels: {
          style: { color: '#333333' },
        },
        title: {
          style: { color: '#333333' },
          text: 'Volume',
        },
        max: showSecondAxis && selectedRowData ? maxVolumeInFirstAxis * 1.25 : null,
      },
      ...(showSecondAxis && selectedRowData
        ? [
            {
              opposite: true,
              labels: {
                style: { color: '#FAA62F' },
              },
              title: {
                style: { color: '#FAA62F' },
                text: 'Volume',
              },
              min: minVolumeInSecondAxis * 0.5,
            },
          ]
        : []),
    ],
    plotOptions: {
      series: {
        events: {
          legendItemClick: e => {
            e.preventDefault();
          },
        },
      },
    },
    tooltip: {
      valueDecimals,
    },
    chart: { height: '20%' },
  };

  return options;
};
