import type { ChangeEvent } from 'react';
import { Form, NavDropdown } from 'react-bootstrap';

export type ChartMenuOptionValue = `category${number}` | 'origin' | 'localOrImported';

interface Options {
  disabled: boolean;
  checked: boolean;
  label: `Category ${number}` | 'Origin' | 'Local or Imported';
  value: ChartMenuOptionValue;
}

interface ListItem {
  prop: string;
  label?: string;
  checked?: boolean;
  disabled: boolean;
  options?: Options[];
}

export interface ForecastChartMenuProps {
  showForecastChart: boolean;
  showCurrentForecastInChart: boolean;
  showModelledForecastInChart: boolean;
  showPreviousForecastInChart: boolean;
  showSecondAxisInChart: boolean;
  secondAxisDataField: ChartMenuOptionValue;
}

export const ForecastChartMenu = (
  props: ForecastChartMenuProps & {
    onChange: (props: ForecastChartMenuProps) => void;
    isForecastByOrigin: boolean;
  }
) => {
  const {
    showForecastChart,
    showCurrentForecastInChart,
    showModelledForecastInChart,
    showPreviousForecastInChart,
    showSecondAxisInChart,
    secondAxisDataField,
    onChange,
    isForecastByOrigin,
  } = props;

  const listItems: ListItem[] = [
    {
      prop: 'showForecastChart',
      label: 'Show chart',
      checked: showForecastChart,
      disabled: false,
    },
    {
      prop: 'showCurrentForecastInChart',
      label: 'Show current forecast',
      checked: showCurrentForecastInChart,
      disabled: !showForecastChart,
    },
    {
      prop: 'showModelledForecastInChart',
      label: 'Show modelled forecast',
      checked: showModelledForecastInChart,
      disabled: !showForecastChart,
    },
    {
      prop: 'showPreviousForecastInChart',
      label: 'Show previous forecast',
      checked: showPreviousForecastInChart,
      disabled: !showForecastChart,
    },
    {
      prop: 'showSecondAxisInChart',
      checked: showSecondAxisInChart,
      label: 'Show 2nd axis',
      disabled: !showForecastChart,
    },
    {
      prop: 'secondAxisDataField',
      disabled: !showForecastChart,
      options: [
        {
          label: 'Category 2',
          value: 'category2',
          checked: secondAxisDataField === 'category2',
          disabled: !showForecastChart || !showSecondAxisInChart,
        },
        {
          label: 'Category 3',
          value: 'category3',
          checked: secondAxisDataField === 'category3',
          disabled: !showForecastChart || !showSecondAxisInChart,
        },
        {
          label: 'Category 4',
          value: 'category4',
          checked: secondAxisDataField === 'category4',
          disabled: !showForecastChart || !showSecondAxisInChart,
        },
        {
          label: 'Category 5',
          value: 'category5',
          checked: secondAxisDataField === 'category5',
          disabled: !showForecastChart || !showSecondAxisInChart,
        },
        {
          label: 'Origin',
          value: 'origin',
          checked: secondAxisDataField === 'origin',
          disabled: !showForecastChart || !showSecondAxisInChart || !isForecastByOrigin,
        },
        {
          label: 'Local or Imported',
          value: 'localOrImported',
          checked: secondAxisDataField === 'localOrImported',
          disabled: !showForecastChart || !showSecondAxisInChart || isForecastByOrigin,
        },
      ],
    },
  ];

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange({
      showForecastChart: props.showForecastChart,
      showCurrentForecastInChart: props.showCurrentForecastInChart,
      showModelledForecastInChart: props.showModelledForecastInChart,
      showPreviousForecastInChart: props.showPreviousForecastInChart,
      showSecondAxisInChart: props.showSecondAxisInChart,
      secondAxisDataField: props.secondAxisDataField,
      [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.id,
    });
  };

  return (
    <NavDropdown title="Chart" id="forecastChartMenu">
      {listItems.map(({ prop, disabled, label, checked, options }) =>
        options ? (
          <Form.Group key={`${prop}-options`}>
            {options.map(({ label, value, disabled, checked }) => (
              <Form.Check
                key={value}
                type="radio"
                id={value}
                checked={checked}
                className="ml-4 my-2"
                disabled={disabled}
                label={label}
                name={prop}
                onChange={handleChange}
              />
            ))}
          </Form.Group>
        ) : (
          <Form.Check
            key={prop}
            style={{ width: '12.5em' }}
            id={prop}
            checked={checked}
            className="ml-2 my-2"
            disabled={disabled}
            label={label}
            name={prop}
            onChange={handleChange}
          />
        )
      )}
    </NavDropdown>
  );
};
