import { v4 as uuidv4 } from 'uuid';

import { NO_DISTRIBUTOR_GUID, UNASSIGNED_PRICE_BAND_ID } from 'src/constants';
import { useMarket } from 'src/markets';
import { useRecords } from 'src/records/hooks/useRecords';
import type { DenormalizedBrandLine } from 'src/records/types/DenormalizedBrandLine';
import type { DenormalizedBrandSales } from 'src/records/types/DenormalizedBrandSales';
import { type DenormalizedOnPremiseSales } from 'src/records/types/DenormalizedOnPremiseSales';
import { BrandLineTypes } from 'src/shared/types';
import { useSyncContext } from 'src/sync';
import type { CategoriesEntity } from 'src/types/entity/Categories';
import { newGUID } from 'src/utils/appUtils';

interface EnsureForecastAndOtherExistParams {
  category5Id: number;
  priceBandId: number;
  isLocal: boolean;
  originId: number;
}

type CheckIfSaleExistsParams = Pick<
  EnsureForecastAndOtherExistParams,
  'category5Id' | 'priceBandId' | 'isLocal' | 'originId'
>;

export function useEnsureForecastAndOtherExist(
  brandSales: DenormalizedBrandSales[],
  onPremiseSales: DenormalizedOnPremiseSales[]
) {
  const { createEntity } = useSyncContext();
  const { market } = useMarket();
  const allBrandLines = useRecords<DenormalizedBrandLine>('brandLines');
  const categories = useRecords<CategoriesEntity>('categories');

  function isForecastByOrigin(category5Id: number) {
    return Boolean(
      categories.find(({ level, id }) => level === 5 && id === category5Id)?.forecastByOrigin
    );
  }

  function checkIfForecastSaleExists(params: CheckIfSaleExistsParams) {
    const { category5Id, priceBandId, isLocal, originId } = params;
    const forecastByOrigin = isForecastByOrigin(category5Id);

    return brandSales.some(
      brandSale =>
        brandSale.brandLineTypeId === BrandLineTypes.FORECAST &&
        brandSale.category5Id === category5Id &&
        brandSale.priceBandId === priceBandId &&
        (forecastByOrigin ? brandSale.originId === originId : brandSale.isLocal === isLocal)
    );
  }

  function getForecastBrandLineGUID({
    category5Id,
    originId,
  }: Pick<EnsureForecastAndOtherExistParams, 'category5Id' | 'originId'>) {
    const forecastByOrigin = isForecastByOrigin(category5Id);

    return allBrandLines.find(
      brandLine =>
        brandLine.brandLineTypeId === BrandLineTypes.FORECAST &&
        brandLine.category5Id === category5Id &&
        (forecastByOrigin ? brandLine.originId === originId : true)
    )?.brandLineGUID;
  }

  function getOtherBrandLineGUID({
    category5Id,
    originId,
  }: Pick<EnsureForecastAndOtherExistParams, 'category5Id' | 'originId'>) {
    const forecastByOrigin = isForecastByOrigin(category5Id);

    return allBrandLines.find(
      brandLine =>
        brandLine.brandLineTypeId === BrandLineTypes.OTHER &&
        brandLine.category5Id === category5Id &&
        (forecastByOrigin ? brandLine.originId === originId : true)
    )?.brandLineGUID;
  }

  function checkIfOtherSaleExists(params: CheckIfSaleExistsParams) {
    const { category5Id, priceBandId, isLocal, originId } = params;
    const forecastByOrigin = isForecastByOrigin(category5Id);

    return brandSales.some(
      brandSale =>
        brandSale.brandLineTypeId === BrandLineTypes.OTHER &&
        brandSale.category5Id === category5Id &&
        brandSale.priceBandId === priceBandId &&
        (forecastByOrigin ? brandSale.originId === originId : brandSale.isLocal === isLocal)
    );
  }

  function checkIfOnPremiseSalesRecordExists(params: CheckIfSaleExistsParams) {
    const { category5Id, priceBandId, isLocal, originId } = params;
    const forecastByOrigin = isForecastByOrigin(category5Id);

    return onPremiseSales.some(
      onPremise =>
        onPremise.category5Id === category5Id &&
        onPremise.priceBandId === priceBandId &&
        (forecastByOrigin ? onPremise.originId === originId : onPremise.isLocal === isLocal)
    );
  }

  function ensureForecastAndOtherExist({
    category5Id,
    priceBandId,
    isLocal,
    originId,
  }: EnsureForecastAndOtherExistParams) {
    const forecastSaleExists = checkIfForecastSaleExists({
      category5Id,
      priceBandId,
      isLocal,
      originId,
    });

    if (!forecastSaleExists) {
      const forecastBrandLineGUID = getForecastBrandLineGUID({ category5Id, originId });
      if (forecastBrandLineGUID) {
        const newForecastSale = {
          brandLineGUID: forecastBrandLineGUID,
          containerSize: {},
          countryId: market.marketId,
          distributorGUID: NO_DISTRIBUTOR_GUID,
          saleGUID: newGUID(),
          isLocal,
          price: {},
          priceBandId,
          volume: {},
        };
        void createEntity('brandSales', newForecastSale);
      } else {
        console.error(
          `No 'Forecast' BrandLine found for category5Id=${category5Id} and originId=${originId}`
        );
      }
    }

    const otherSaleExists = checkIfOtherSaleExists({
      category5Id,
      priceBandId,
      isLocal,
      originId,
    });

    if (!otherSaleExists && priceBandId !== UNASSIGNED_PRICE_BAND_ID) {
      const otherBrandLineGUID = getOtherBrandLineGUID({ category5Id, originId });
      if (otherBrandLineGUID) {
        const newOtherSale = {
          brandLineGUID: otherBrandLineGUID,
          containerSize: {},
          countryId: market.marketId,
          distributorGUID: NO_DISTRIBUTOR_GUID,
          saleGUID: newGUID(),
          isLocal,
          price: {},
          priceBandId,
          volume: {},
        };
        void createEntity('brandSales', newOtherSale);
      } else {
        console.error(
          `No 'Other' BrandLine found for category5Id=${category5Id} and originId=${originId}`
        );
      }
    }

    const onPremiseSalesRecordExists = checkIfOnPremiseSalesRecordExists({
      category5Id,
      priceBandId,
      isLocal,
      originId,
    });

    if (!onPremiseSalesRecordExists) {
      const forecastByOrigin = isForecastByOrigin(category5Id);
      const newOnPremise = {
        onPremiseGUID: uuidv4().toUpperCase(),
        countryId: market.marketId,
        category5Id,
        priceBandId,
        isLocal,
        originId: forecastByOrigin ? originId : 0,
      };

      void createEntity('onPremiseSales', newOnPremise);
    }
  }

  return { ensureForecastAndOtherExist };
}
